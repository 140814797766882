// src/Checklist.js


import React, { useState, useEffect, useRef } from 'react';
import ChecklistItem from './ChecklistItem';
import BackupPage from './BackupPage';
import ProgressBar from './ProgressBar';

const Checklist = ({ checklistData, currentStep, setCurrentStep }) => {
  const [showBackup, setShowBackup] = useState(false);
  const [selectedTask, setSelectedTask] = useState({ deadline: '', task: '', action: '' });

  // Create an array of refs for each checklist item
  const itemRefs = useRef([]);

  // Initialize refs for each checklist item
  useEffect(() => {
    itemRefs.current = checklistData.map((_, i) => itemRefs.current[i] || React.createRef());
  }, [checklistData]);

  // Scroll the current item into view whenever currentStep changes
  useEffect(() => {
    if (currentStep < checklistData.length) {
      const currentRef = itemRefs.current[currentStep];
      if (currentRef && currentRef.current) {
        currentRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center', // Positions the item in the center of the container
        });
      }
    }
  }, [currentStep, checklistData.length]);

  // Additional useEffect to handle scrolling when BackupPage is closed
  useEffect(() => {
    if (!showBackup) { // BackupPage is closed
      const currentRef = itemRefs.current[currentStep];
      if (currentRef && currentRef.current) {
        currentRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  }, [showBackup, currentStep]);

  const handleGoClick = () => {
    if (currentStep < checklistData.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleNoGoClick = (task, action) => {
    setSelectedTask({ task, action });
    setShowBackup(true);
  };

  const closeBackupPage = () => {
    setShowBackup(false);
  };

  if (showBackup) {
    return (
      <BackupPage
        task={selectedTask.task}
        action={selectedTask.action}
        closeBackupPage={closeBackupPage}
      />
    );
  }

  return (
    <div className="checklistContainer">
      <ProgressBar currentStep={currentStep} totalSteps={checklistData.length} />
      {checklistData.map((item, index) => (
        <ChecklistItem
          key={index}
          ref={itemRefs.current[index]} // Attach the ref to ChecklistItem
          step={index}
          currentStep={currentStep}
          item={item}
          handleGoClick={handleGoClick}
          handleNoGoClick={() => handleNoGoClick(item.task, item.action)}
        />
      ))}
      <div className="endMessage">This is the end of the checklist</div>
    </div>
  );
};

export default Checklist;






/* 
const checklistData = [
    {
        task: "Turn on the 3 screens in the studio",
        action: "Check the batteries and replace if necessary.",
        deadline: "17:00" // Define the deadline time for each item
      },
      {
        task: "Repositioning the seats properly",
        action: "Other action 2.",
        deadline: { hours: 13, minutes: 30 } // Define the deadline time for each item
      },
      {
        task: "Check the microphone positions and that they are correctly connected",
        action: "Other action 3.",
        deadline: { hours: 13, minutes: 30 }, // Define the deadline time for each item
      },
      {
        task: "Start all computers (PPT content + producer)",
        action: "Other action 4.",
        deadline: { hours: 13, minutes: 30 }, // Define the deadline time for each item
      },
      {
        task: "Turn on audio mixing table 1 and check audio connections",
        action: "Check the batteries and replace if necessary.",
        deadline: { hours: 13, minutes: 30 }, // Define the deadline time for each item
      },
      {
        task: "Check that the monitor speakers are switched on, with low sound for feedback ",
        action: "Check the batteries and replace if necessary.",
        deadline: { hours: 13, minutes: 30 }, // Define the deadline time for each item
      },
      {
        task: "On the Alienware: go to the bottom right of the desktop, select 'StreamDeck', make a right click and select 'Quit Streamdeck' ",
        action: "Check the batteries and replace if necessary.",
        deadline: { hours: 13, minutes: 30 }, // Define the deadline time for each item
      },
      {
        task: "Launch vMix 'setup'",
        action: "Check the batteries and replace if necessary.",
        deadline: { hours: 13, minutes: 30 }, // Define the deadline time for each item
      },
      {
        task: "Launch 'Companion' , select the 'All Interfaces: 0.0.0' and click on 'Launch GUI'",
        action: "Check the batteries and replace if necessary.",
        deadline: { hours: 13, minutes: 30 }, // Define the deadline time for each item
      },
      {
        task: "Go back to vMix 'setup' and click on 'External' ",
        action: "Check the batteries and replace if necessary.",
        deadline: { hours: 13, minutes: 30 }, // Define the deadline time for each item
      },
      {
        task: "The stream deck in the control room should now be turned on, test the buttons to see if the stream deck is well coordinated with vMix. ",
        action: "Check the batteries and replace if necessary.",
        deadline: { hours: 13, minutes: 30 }, // Define the deadline time for each item
      },
      {
        task: "If the stream deck is not well coordinated with vMix do this: Go to vMix, in 'Settings', then click on 'Web Controller', the port ends with 8099, change the end to 8098. Go back to 'Settings', 'Web Controller' then put back the original port ending with 8099. ",
        action: "Check the batteries and replace if necessary.",
        deadline: { hours: 13, minutes: 30 }, // Define the deadline time for each item
      },
      {
        task: "Check if the stream deck is well coordinated. ",
        action: "Check the batteries and replace if necessary.",
        deadline: { hours: 13, minutes: 30 }, // Define the deadline time for each item
      },
      {
        task: "If still not, check the numbers to make sure that the 'Website Address in Vmix is the same as 'Target host' in Companion",
        action: "Check the batteries and replace if necessary.",
        deadline: { hours: 13, minutes: 30 }, // Define the deadline time for each item
      },
      {
        task: "Go to the 'Timer' scene, right click, select 'Title Edition' and change the time.",
        action: "Check the batteries and replace if necessary.",
        deadline: { hours: 13, minutes: 30 }, // Define the deadline time for each item
      },
      {
        task: "On the second computer, put the ppt you want to broadcast. Check for all videos: Playback > Volume > Mute & Start > Automatically & check Loop until stopped",
        action: "Check the batteries and replace if necessary.",
        deadline: { hours: 13, minutes: 30 }, // Define the deadline time for each item
      },
      {
        task: "Check that the clicker works and has enough battery.",
        action: "Check the batteries and replace if necessary.",
        deadline: { hours: 13, minutes: 30 }, // Define the deadline time for each item
      },
      {
        task: "Check the animations on the stream deck in the studio (01 Signals, 02 Did you know etc)",
        action: "Check the batteries and replace if necessary.",
        deadline: { hours: 13, minutes: 30 }, // Define the deadline time for each item
      },     
       {
        task: "Change Tiny.cc to link to Monday/Tuesday Teams Live Event URL (Click on 'Get attendee link' on the Live Event invitation)",
        action: "Check the batteries and replace if necessary.",
        deadline: { hours: 13, minutes: 30 }, // Define the deadline time for each item
      },
      {
        task: "Check network speed and availability",
        action: "Check the batteries and replace if necessary.",
        deadline: { hours: 13, minutes: 30 }, // Define the deadline time for each item
      },
      {
        task: "Make a test call to check sound (waiting music, jingle, microphones)",
        action: "Check the batteries and replace if necessary.",
        deadline: { hours: 13, minutes: 30 }, // Define the deadline time for each item
      },
      {
        task: "Check the noise cancelling option",
        action: "Check the batteries and replace if necessary.",
        deadline: { hours: 13, minutes: 30 }, // Define the deadline time for each item
      },
      {
        task: "Start the live event and broadcast waiting screen with music and timer.",
        action: "Check the batteries and replace if necessary.",
        deadline: { hours: 13, minutes: 30 }, // Define the deadline time for each item
      },
      {
        task: "Click on RECORD (where? In vMix? OBS? Teams? Please specify)",
        action: "Check the batteries and replace if necessary.",
        deadline: { hours: 13, minutes: 30 }, // Define the deadline time for each item
      },
      {
        task: "Check the chat window",
        action: "Check the batteries and replace if necessary.",
        deadline: { hours: 13, minutes: 30 }, // Define the deadline time for each item
      },
      {
        task: "Post in the chat window (What exactly?)",
        action: "Check the batteries and replace if necessary.",
        deadline: { hours: 13, minutes: 30 }, // Define the deadline time for each item
      },
      {
        task: "End recording",
        action: "Check the batteries and replace if necessary.",
        deadline: { hours: 13, minutes: 30 }, // Define the deadline time for each item
      },
      {
        task: "Save recording (Where, how? What format? Other details?)",
        action: "Check the batteries and replace if necessary.",
        deadline: { hours: 13, minutes: 30 }, // Define the deadline time for each item
      },
// Add all other items...
];

const Checklist = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [showBackup, setShowBackup] = useState(false);
    const [selectedTask, setSelectedTask] = useState({ deadline: '', task: '', action: '' });
  
    const handleGoClick = () => {
      if (currentStep < checklistData.length - 1) {
        setCurrentStep(currentStep + 1);
      }
    };
  
    const handleNoGoClick = (task, action) => {
      setSelectedTask({ task, action });
      setShowBackup(true);
    };
  
    const closeBackupPage = () => {
      setShowBackup(false);
    };
  
    if (showBackup) {
      return <BackupPage task={selectedTask.task} action={selectedTask.action} closeBackupPage={closeBackupPage} />;
    }

    return (
        <><div className="checklistContainer">
            <ProgressBar currentStep={currentStep} totalSteps={checklistData.length} />
            {checklistData.map((item, index) => (
                <ChecklistItem
                    key={index}
                    step={index}
                    currentStep={currentStep}
                    item={item.task}
                    action={item.action}
                    handleGoClick={handleGoClick}
                    handleNoGoClick={() => handleNoGoClick(item.task, item.action)} />
            ))}
        </div><div className="endMessage">This is the end of the checklist</div></>
      );
};
  
  export default Checklist;

   */