// src/App.js
import './App.css';
import React, { useState } from 'react';
import Checklist from './Checklist';
import ProgressBar from './ProgressBar';
import CurrentTime from './CurrentTime';
import Signals_Chklst from './Signals_Chklst';
import Signals_Replay_Chklst from './Signals_Replay_Chklst';

const App = () => {
  const [showSignalsChklst, setShowSignalsChklst] = useState(false);
  const [showSignalsReplayChklst, setShowSignalsReplayChklst] = useState(false);

  return (
    <div className="mainContainer">
      {showSignalsChklst && <Signals_Chklst setShowSignalsChklst={setShowSignalsChklst} showHeaderAndButtons={false} />}
      {showSignalsReplayChklst && <Signals_Replay_Chklst setShowSignalsReplayChklst={setShowSignalsReplayChklst} />}
  
      {!showSignalsChklst && !showSignalsReplayChklst && (
        <>
          <div className="headline">ASIC Checklists</div>
          <div className="mainMenu">
            <div className="leftColumn">
              <button className="menuButtons" onClick={() => setShowSignalsChklst(true)}><div className="buttonImageOne"></div>Checklist Signals - Monday</button>
              <button className="menuButtons" onClick={() => setShowSignalsReplayChklst(true)}><div className="buttonImageOne"></div>Checklist Signals Replay - Tuesday</button>
              <button className="menuButtons" onClick={() => setShowSignalsChklst(true)}><div className="buttonImageFour"></div>Empty</button>
              <button className="menuButtons" onClick={() => setShowSignalsReplayChklst(true)}><div className="buttonImageFive"></div>Empty</button>
            </div>
            <div className="rightColumn">
              <button className="menuButtons" onClick={() => setShowSignalsChklst(true)}><div className="buttonImageThree"></div>Empty R</button>
              <button className="menuButtons" onClick={() => setShowSignalsReplayChklst(true)}><div className="buttonImageSix"></div>Empty R</button>
              <button className="menuButtons" onClick={() => setShowSignalsChklst(true)}><div className="buttonImageSeven"></div>Empty R</button>
              <button className="menuButtons" onClick={() => setShowSignalsReplayChklst(true)}><div className="buttonImageEight"></div>Empty R</button>
            </div>
          </div>
        </>
      )}
  
      {/* <div className="clock"><CurrentTime /></div> */}
    </div>
  );
};

export default App;