// src/ProgressBar.js
import React from 'react';

const ProgressBar = ({ currentStep, totalSteps }) => {
  const progress = ((currentStep + 1) / totalSteps) * 100;

  return (
    <div style={{ width: '100%', backgroundColor: '#f0f0f0', position: 'fixed', top: 0, left: 0 }}>
      <div style={{ width: `${progress}%`, backgroundColor: 'green', height: '1vh' }}></div>
    </div>
  );
};

export default ProgressBar;