// src/Signals_Chklst.js
import './App.css';
import React, { useState } from 'react';
import Checklist from './Checklist';
import ProgressBar from './ProgressBar';
import CurrentTime from './CurrentTime';

const Signals_Replay_Chklst = ({ setShowSignalsReplayChklst }) => {

    const [checklistData, setChecklistData] = useState([
      {
        task: "Download the replay of Monday evening",
        action: "Action 1",
        deadline: "08:10"
      },
      {
        task: "Update the tiny link with the good Town Hall event link (check in the teams calendar, click on the event)",
        action: "Action 2",
        deadline: "08:20"
      },
      {
        task: "Open OBS (Use this tuto with images to broadcast the replay with OBS)",
        action: "Action 3.",
        deadline: "08:22"
      },
      {
        task: "In File>Settings>Audio make sure to select Cable Input as monitoring device",
        action: "Action 4",
        deadline: "08:23"
      },
      {
        task: "In Audio Mixer> Setting>Make sure it's Monitor and Output for the media source",
        action: "Action 6",
        deadline: "08:24"
      },
      {
        task: "Select start Virtual Camera​",
        action: "Action 5",
        deadline: "08:25"
      },
      {
        task: "Join the Town Hall",
        action: "Action 5",
        deadline: "08:26"
      },
      {
        task: "Put you as the presenter",
        action: "Action 5",
        deadline: "08:27"
      },
      {
        task: "In Audio settings > Micro should be 'CABLE Output'",
        action: "Action 5",
        deadline: "08:28"
      },
      {
        task: "In Audio settings > Turn off the Noise Supression",
        action: "Action 5",
        deadline: "08:29"
      },
      {
        task: "In Audio settings > Turn on High fidelity music mode (important to hear the intro music)",
        action: "Action 5",
        deadline: "08:30"
      },
      {
        task: "Check that the audio bar is well animated to be sure sound is okay",
        action: "Action 5",
        deadline: "08:31"
      },
      {
        task: "In Video setting > Camera sould be 'OBS Virtual Camera'",
        action: "Action 5",
        deadline: "08:32"
      },
      {
        task: "Move the video cursor when there are 5 minutes of waiting left with the music and click on Play",
        action: "Action 5",
        deadline: "08:33"
      },
      {
        task: "Check that the video has started properly on your Town Hall, ensure that the audio bar is animated",
        action: "Action 5",
        deadline: "08:38"
      },
      {
        task: "Click on 'Start meeting'",
        action: "Action 5",
        deadline: "08:55"
      },
      {
        task: "Once the live session is over, make sure to click on 'End meeting,' because if you click 'Leave meeting,' the Town Hall will remain live",
        action: "Action 5",
        deadline: "09:30"
      },
      
      // ... other checklist items
    ]);
  
    const [currentStep, setCurrentStep] = useState(0);

    return (
      <div className="outerContainer">
        <div className="progressBar">
          <ProgressBar currentStep={currentStep} totalSteps={checklistData.length} />
        </div>
        {/* <button onClick={() => setShowSignalsReplayChklst(false)}>Back Home</button> */}
        <div className="headline">SIGNALS Checklist - Tuesday Morning Replay</div>
        {/* <h1>SIGNALS Checklist - Tuesday Morning Replay</h1> */}
        <Checklist checklistData={checklistData} currentStep={currentStep} setCurrentStep={setCurrentStep}/> 
        <div className="clock"><CurrentTime /></div><div className="homeButton"><button onClick={() => setShowSignalsReplayChklst(false)}>List</button></div>
  
        
      </div>
    );
  };
  
  export default Signals_Replay_Chklst;