// src/Signals_Chklst.js
import './App.css';
import React, { useState } from 'react';
import Checklist from './Checklist';
import ProgressBar from './ProgressBar';
import CurrentTime from './CurrentTime';

const Signals_Chklst = ({ setShowSignalsChklst, showHeaderAndButtons }) => {

    const [checklistData, setChecklistData] = useState([
      {
        task: "Turn on ASIC lights",
        action: "Action 1",
        deadline: "11:00"
      },
      {
        task: "Turn on the ASIC red light to prevent any disturbance during the event",
        action: "Action 2",
        deadline: "11:02"
      },
      {
        task: "Turn on Green Studio lamps using both remotes (white tape and green tape)",
        action: "Action 3.",
        deadline: "11:04"
      },
      {
        task: "Turn on camera, with on/off button",
        action: "Action 4",
        deadline: "11:06"
      },
      {
        task: "Power up HP PC and Alienware PC",
        action: "Action 6",
        deadline: "11:13"
      },
      {
        task: "Turn on mixing board (rear button)",
        action: "Action 5",
        deadline: "11:15"
      },
      {
        task: "Log into Alienware PC (using standard password)",
        action: "Action 5",
        deadline: "11:17"
      },
      {
        task: "Launch Companion software and verify Stream Deck icons display correctly",
        action: "Action 5",
        deadline: "11:19"
      },
      {
        task: "Open 'SIGNALS ONLY' file from desktop",
        action: "Action 5",
        deadline: "11:24"
      },
      {
        task: "Access PowerPoint presentation from Teams: 'Signals (internal) > 0_Signals working area 🚧 > Files' on HP PC",
        action: "Action 5",
        deadline: "11:26"
      },
      {
        task: "Set PowerPoint to presentation mode",
        action: "Action 5",
        deadline: "11:28"
      },
      {
        task: "On Vmix select the button 'External' to activate the webcam (at the bottom)",
        action: "Action 5",
        deadline: "11:30"
      },
      {
        task: "Test Stream Deck functionality from chairs + clicker",
        action: "Action 5",
        deadline: "15:00"
      },
      {
        task: "Perform a microphone test :",
        action: "Action 5",
        deadline: "15:05"
      },
      {
        task: "Connect to the live event from Alienwar without starting it. Ideally with someone else",
        action: "Action 5",
        deadline: "15:05"
      },
      {
        task: "Set the audio sources to:  Micro = CABLE Output (VB-Audio Virtual Cable) HP = Ligne (2 - ZOOM L-12 Audio)",
        action: "Action 5",
        deadline: "15:07"
      },
      {
        task: "Connect to event 11 minutes before start time",
        action: "Action 5",
        deadline: "17:15"
      },
      {
        task: "Set Vmix to the scene 'Attente avec audio' ",
        action: "Action 5",
        deadline: "17:17"
      },
      {
        task: "Start the live meeting",
        action: "Action 5",
        deadline: "17:19"
      },
      {
        task: "Press the recording button",
        action: "Action 5",
        deadline: "17:20"
      },
      {
        task: "Check again if the audio is good with the intro music",
        action: "Action 5",
        deadline: "17:21"
      },
      {
        task: "At the end of the presentation, end the meeting from Alienware PC Teams",
        action: "Action 5",
        deadline: "18:00"
      },
      {
        task: "Stop the external + recording button on Vmix",
        action: "Action 5",
        deadline: "18:01"
      },
      {
        task: "Retrieve the file from the desktop of the Alienware PC ",
        action: "Action 5",
        deadline: "18:02"
      },
      {
        task: "Send the file on the direct messages conversation called 'Signals team - Régie & Replay' ",
        action: "Action 5",
        deadline: "18:02"
      },
      {
        task: "After the recording has been sent, shutdown both PC",
        action: "Action 5",
        deadline: "18:15"
      },
      {
        task: "Shutdown the mixing board",
        action: "Action 5",
        deadline: "18:17"
      },
      {
        task: "Shutdown both of the green studio screens",
        action: "Action 5",
        deadline: "18:18"
      },
      {
        task: "Shutdown the green studio lights ",
        action: "Action 5",
        deadline: "18:19"
      },
      {
        task: "Shutdown Asic lights",
        action: "Action 5",
        deadline: "18:20"
      },
      {
        task: "Se laver les dents et se coucher",
        action: "Action 5",
        deadline: "22:20"
      },
      // ... other checklist items
    ]);
  
    const [currentStep, setCurrentStep] = useState(0);

    return (
      <><div className="outerContainer">

        <div className="progressBar">
          <ProgressBar currentStep={currentStep} totalSteps={checklistData.length} />
        </div>
        {/* <button onClick={() => setShowSignalsChklst(false)}>Back Home</button> */}
        <div className="headline">SIGNALS Checklist - Monday Night</div>
        {/* <h1>SIGNALS Checklist - Monday Night</h1> */}

        <Checklist checklistData={checklistData} currentStep={currentStep} setCurrentStep={setCurrentStep} />


        


      </div><div className="clock"><CurrentTime /></div><div className="homeButton"><button onClick={() => setShowSignalsChklst(false)}>List</button></div></>
    );
  };
  
  export default Signals_Chklst;