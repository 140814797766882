// src/BackupPage.js
import React from 'react';

const BackupPage = ({ task, action, closeBackupPage }) => {
  return (
    <div className="backupPageContainer">
      <h2>{task}</h2>
      <p>No-Go Action: {action}</p>
      <button onClick={closeBackupPage}>Return to Checklist</button>
    </div>
  );
};

export default BackupPage;


/* import React from 'react';

const BackupPage = ({ task, action, closeBackupPage }) => {
  return (
    <div>
      <h2>{task}</h2>
      <p>No-Go Action: {action}</p>
      <button onClick={closeBackupPage}>Return to Checklist</button>
    </div>
  );
};

export default BackupPage; */
