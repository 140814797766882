// src/ChecklistItem.js


import React, { useState, useEffect } from 'react';

// Use React.forwardRef to forward the ref to the DOM element
const ChecklistItem = React.forwardRef(
  ({ step, currentStep, item, handleGoClick, handleNoGoClick }, ref) => {
    const [isPastDeadline, setIsPastDeadline] = useState(false);
    const [formattedDeadline, setFormattedDeadline] = useState('');

    useEffect(() => {
      // Format the deadline - handles string or object formats.
      if (typeof item.deadline === 'string') {
        setFormattedDeadline(item.deadline);
      } else if (typeof item.deadline === 'object') {
        const { hours, minutes } = item.deadline;
        setFormattedDeadline(
          `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
        );
      }
    }, [item.deadline]);

    useEffect(() => {
      const intervalId = setInterval(() => {
        const now = new Date();
        const hours = now.getHours();
        const minutes = now.getMinutes();
        const currentTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

        // Check if past deadline and update state
        if (currentTime > formattedDeadline) {
          setIsPastDeadline(true);
        } else {
          setIsPastDeadline(false);
        }

        // Midnight reset logic
        if (hours === 0 && minutes === 0) {
          setIsPastDeadline(false);
        }
      }, 1000);

      return () => clearInterval(intervalId); // Cleanup on unmount
    }, [formattedDeadline]);

    const isActive = step === currentStep;

    return (
      <div
        ref={ref} // Attach the forwarded ref to the div
        className={`itemLine ${isActive ? 'current' : ''}`}
        style={{ opacity: isActive ? 1 : 0.3 }}
      >
        <div className="itemLeft">
          <div
            className="deadlineText"
            style={{ color: isPastDeadline ? 'red' : 'black' }}
          >
            {formattedDeadline} {/* Display formatted deadline */}
          </div>
          <div className="itemText">{item.task}</div>
        </div>
        {isActive && (
          <div className="buttons">
            <button className="goButton" onClick={handleGoClick}>
              Go
            </button>
            <button
              className="nogoButton"
              onClick={() => handleNoGoClick(item.task, item.action)}
            >
              No Go
            </button>
          </div>
        )}
      </div>
    );
  }
);

export default ChecklistItem;






/* const ChecklistItem = ({ step, currentStep, item, action, deadline, handleGoClick, handleNoGoClick }) => {
    const isActive = step === currentStep;
return (
<div className="itemLine" style={{ opacity: isActive ? 1 : 0.3 }}>

<div className="itemText"><p>{item}</p></div>
{isActive && (
    <>      
            <div className="buttons">
                <button className="goButton" onClick={handleGoClick}>Go</button>
                <button className="nogoButton" onClick={() => handleNoGoClick(item)}>No Go</button>
            </div></>
)}
</div>
);
};
export default ChecklistItem; */
