// src/CurrentTime.js
import React, { useState, useEffect } from 'react';

const CurrentTime = ({ deadline }) => {
  const [currentTime, setCurrentTime] = useState(new Date().toLocaleTimeString());
  let isPastDeadline = false;

  if (deadline) {
    isPastDeadline = new Date() > new Date().setHours(deadline.hours, deadline.minutes);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date().toLocaleTimeString());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  console.log('Deadline:', deadline);
  console.log('Is past deadline:', isPastDeadline);

  return <span /* style={{ color: isPastDeadline ? 'red' : 'black' }} */>{currentTime}</span>;
};

export default CurrentTime;